@font-face {
    font-family: "BoostUpIcons";
    src: url("../fonts/BoostUpIcons.ttf?4361f38054dd298a1b7dffeeb6b43fe6") format("truetype"),
url("../fonts/BoostUpIcons.woff?4361f38054dd298a1b7dffeeb6b43fe6") format("woff"),
url("../fonts/BoostUpIcons.woff2?4361f38054dd298a1b7dffeeb6b43fe6") format("woff2");
}

i[class^="bu-icon-"]:before, i[class*=" bu-icon-"]:before {
    font-family: BoostUpIcons !important;
    font-style: normal;
    font-weight: normal !important;
    font-variant: normal;
    text-transform: none;
    line-height: 1;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
}

.bu-icon-account-pivot:before {
    content: "\f101";
}
.bu-icon-ai_icon:before {
    content: "\f102";
}
.bu-icon-ai_regenerate:before {
    content: "\f103";
}
.bu-icon-ai_undo:before {
    content: "\f104";
}
.bu-icon-arrow_left:before {
    content: "\f105";
}
.bu-icon-arrow_right:before {
    content: "\f106";
}
.bu-icon-attachment:before {
    content: "\f107";
}
.bu-icon-audio:before {
    content: "\f108";
}
.bu-icon-badge_check_solid:before {
    content: "\f109";
}
.bu-icon-badge_clock_solid:before {
    content: "\f10a";
}
.bu-icon-badge_error_solid:before {
    content: "\f10b";
}
.bu-icon-badge_info_outline:before {
    content: "\f10c";
}
.bu-icon-badge_info_solid:before {
    content: "\f10d";
}
.bu-icon-badge_question_outline:before {
    content: "\f10e";
}
.bu-icon-badge_question_solid:before {
    content: "\f10f";
}
.bu-icon-badge_warning_outline:before {
    content: "\f110";
}
.bu-icon-badge_warning_solid:before {
    content: "\f111";
}
.bu-icon-bar_graph:before {
    content: "\f112";
}
.bu-icon-bookmark:before {
    content: "\f113";
}
.bu-icon-boostup_logo_part_1:before {
    content: "\f114";
}
.bu-icon-boostup_logo_part_2:before {
    content: "\f115";
}
.bu-icon-boostup_logo:before {
    content: "\f116";
}
.bu-icon-boostup_part_1:before {
    content: "\f117";
}
.bu-icon-boostup_part_2:before {
    content: "\f118";
}
.bu-icon-boostup:before {
    content: "\f119";
}
.bu-icon-calendar:before {
    content: "\f11a";
}
.bu-icon-call:before {
    content: "\f11b";
}
.bu-icon-change_down:before {
    content: "\f11c";
}
.bu-icon-change_up:before {
    content: "\f11d";
}
.bu-icon-chart_label_dot:before {
    content: "\f11e";
}
.bu-icon-chart_label_line_dash:before {
    content: "\f11f";
}
.bu-icon-chart_label_line_dot:before {
    content: "\f120";
}
.bu-icon-chart_label_line_solid:before {
    content: "\f121";
}
.bu-icon-check_circle:before {
    content: "\f122";
}
.bu-icon-checkbox_checked_full:before {
    content: "\f123";
}
.bu-icon-checkbox_checked:before {
    content: "\f124";
}
.bu-icon-checkbox_empty:before {
    content: "\f125";
}
.bu-icon-checkbox_indeterminate_full:before {
    content: "\f126";
}
.bu-icon-checkbox_indeterminate:before {
    content: "\f127";
}
.bu-icon-checked:before {
    content: "\f128";
}
.bu-icon-chevron_down:before {
    content: "\f129";
}
.bu-icon-chevron_left:before {
    content: "\f12a";
}
.bu-icon-chevron_right:before {
    content: "\f12b";
}
.bu-icon-chevron_up:before {
    content: "\f12c";
}
.bu-icon-clipboard:before {
    content: "\f12d";
}
.bu-icon-clock:before {
    content: "\f12e";
}
.bu-icon-close_big:before {
    content: "\f12f";
}
.bu-icon-close_popup:before {
    content: "\f130";
}
.bu-icon-collapse_icon:before {
    content: "\f131";
}
.bu-icon-collapse:before {
    content: "\f132";
}
.bu-icon-column_graph_box:before {
    content: "\f133";
}
.bu-icon-column_graph:before {
    content: "\f134";
}
.bu-icon-combined_graphs:before {
    content: "\f135";
}
.bu-icon-configuration:before {
    content: "\f136";
}
.bu-icon-contact-pivot-round:before {
    content: "\f137";
}
.bu-icon-contact-pivot:before {
    content: "\f138";
}
.bu-icon-contacts_linked:before {
    content: "\f139";
}
.bu-icon-copy_icon:before {
    content: "\f13a";
}
.bu-icon-copy:before {
    content: "\f13b";
}
.bu-icon-cross_circle:before {
    content: "\f13c";
}
.bu-icon-customobject-pivot-round:before {
    content: "\f13d";
}
.bu-icon-customobject-pivot:before {
    content: "\f13e";
}
.bu-icon-danger_circle:before {
    content: "\f13f";
}
.bu-icon-dialogue:before {
    content: "\f140";
}
.bu-icon-download:before {
    content: "\f141";
}
.bu-icon-drag:before {
    content: "\f142";
}
.bu-icon-error:before {
    content: "\f143";
}
.bu-icon-event-pivot-round:before {
    content: "\f144";
}
.bu-icon-event-pivot:before {
    content: "\f145";
}
.bu-icon-file_blank:before {
    content: "\f146";
}
.bu-icon-file_document:before {
    content: "\f147";
}
.bu-icon-filter:before {
    content: "\f148";
}
.bu-icon-full_screen:before {
    content: "\f149";
}
.bu-icon-grouped_bar_graph:before {
    content: "\f14a";
}
.bu-icon-grouped_column_graph:before {
    content: "\f14b";
}
.bu-icon-horizontal_resize:before {
    content: "\f14c";
}
.bu-icon-image:before {
    content: "\f14d";
}
.bu-icon-interactive_item_resize_handle:before {
    content: "\f14e";
}
.bu-icon-json-file:before {
    content: "\f14f";
}
.bu-icon-layers:before {
    content: "\f150";
}
.bu-icon-lead-pivot-round:before {
    content: "\f151";
}
.bu-icon-lead-pivot:before {
    content: "\f152";
}
.bu-icon-line_graph:before {
    content: "\f153";
}
.bu-icon-link:before {
    content: "\f154";
}
.bu-icon-location:before {
    content: "\f155";
}
.bu-icon-lock:before {
    content: "\f156";
}
.bu-icon-log_out:before {
    content: "\f157";
}
.bu-icon-logo_datadog:before {
    content: "\f158";
}
.bu-icon-logo_google:before {
    content: "\f159";
}
.bu-icon-logo_hubspot:before {
    content: "\f15a";
}
.bu-icon-logo_linkedin:before {
    content: "\f15b";
}
.bu-icon-logo_outlook:before {
    content: "\f15c";
}
.bu-icon-logo_salesforce:before {
    content: "\f15d";
}
.bu-icon-mail:before {
    content: "\f15e";
}
.bu-icon-media_fast_forward:before {
    content: "\f15f";
}
.bu-icon-media_pause:before {
    content: "\f160";
}
.bu-icon-media_play:before {
    content: "\f161";
}
.bu-icon-media_rewind:before {
    content: "\f162";
}
.bu-icon-media_skip_back:before {
    content: "\f163";
}
.bu-icon-media_skip_forward:before {
    content: "\f164";
}
.bu-icon-media_stop:before {
    content: "\f165";
}
.bu-icon-meeting_timeline_icon:before {
    content: "\f166";
}
.bu-icon-menu_drawer:before {
    content: "\f167";
}
.bu-icon-menu_horiz:before {
    content: "\f168";
}
.bu-icon-menu_vert:before {
    content: "\f169";
}
.bu-icon-microphone:before {
    content: "\f16a";
}
.bu-icon-mindtickle_call_icon:before {
    content: "\f16b";
}
.bu-icon-mindtickle_call_transparent:before {
    content: "\f16c";
}
.bu-icon-minus:before {
    content: "\f16d";
}
.bu-icon-money:before {
    content: "\f16e";
}
.bu-icon-multi_currency:before {
    content: "\f16f";
}
.bu-icon-nav_accounts:before {
    content: "\f170";
}
.bu-icon-nav_activity:before {
    content: "\f171";
}
.bu-icon-nav_admin:before {
    content: "\f172";
}
.bu-icon-nav_calls:before {
    content: "\f173";
}
.bu-icon-nav_dashboard:before {
    content: "\f174";
}
.bu-icon-nav_forecast:before {
    content: "\f175";
}
.bu-icon-nav_meetings:before {
    content: "\f176";
}
.bu-icon-nav_metric:before {
    content: "\f177";
}
.bu-icon-nav_opportunities:before {
    content: "\f178";
}
.bu-icon-nav_pipeline:before {
    content: "\f179";
}
.bu-icon-nav_prospect:before {
    content: "\f17a";
}
.bu-icon-nav_seller:before {
    content: "\f17b";
}
.bu-icon-nav_settings:before {
    content: "\f17c";
}
.bu-icon-nav_targets:before {
    content: "\f17d";
}
.bu-icon-nav_todos:before {
    content: "\f17e";
}
.bu-icon-No-event-icon:before {
    content: "\f17f";
}
.bu-icon-notes:before {
    content: "\f180";
}
.bu-icon-opp_split_icon:before {
    content: "\f181";
}
.bu-icon-opportunity-pivot-round:before {
    content: "\f182";
}
.bu-icon-opportunity-pivot:before {
    content: "\f183";
}
.bu-icon-pencil:before {
    content: "\f184";
}
.bu-icon-pie_chart:before {
    content: "\f185";
}
.bu-icon-pin:before {
    content: "\f186";
}
.bu-icon-play:before {
    content: "\f187";
}
.bu-icon-plus:before {
    content: "\f188";
}
.bu-icon-print:before {
    content: "\f189";
}
.bu-icon-quick_help:before {
    content: "\f18a";
}
.bu-icon-read_only:before {
    content: "\f18b";
}
.bu-icon-refresh_icon:before {
    content: "\f18c";
}
.bu-icon-reload:before {
    content: "\f18d";
}
.bu-icon-risk_dot:before {
    content: "\f18e";
}
.bu-icon-rotated_pin:before {
    content: "\f18f";
}
.bu-icon-rotated_pinned:before {
    content: "\f190";
}
.bu-icon-search:before {
    content: "\f191";
}
.bu-icon-sentiment_face_negative:before {
    content: "\f192";
}
.bu-icon-sentiment_face_neutral:before {
    content: "\f193";
}
.bu-icon-sentiment_face_positive:before {
    content: "\f194";
}
.bu-icon-settings:before {
    content: "\f195";
}
.bu-icon-share:before {
    content: "\f196";
}
.bu-icon-sorting_ascending:before {
    content: "\f197";
}
.bu-icon-sorting_descending:before {
    content: "\f198";
}
.bu-icon-splits:before {
    content: "\f199";
}
.bu-icon-square_minus:before {
    content: "\f19a";
}
.bu-icon-square_plus:before {
    content: "\f19b";
}
.bu-icon-stacked_bar_graph:before {
    content: "\f19c";
}
.bu-icon-stacked_column_graph:before {
    content: "\f19d";
}
.bu-icon-submission-pivot-round:before {
    content: "\f19e";
}
.bu-icon-submission-pivot:before {
    content: "\f19f";
}
.bu-icon-submit:before {
    content: "\f1a0";
}
.bu-icon-sync:before {
    content: "\f1a1";
}
.bu-icon-table_columns_selection:before {
    content: "\f1a2";
}
.bu-icon-table:before {
    content: "\f1a3";
}
.bu-icon-timeline_alert_dot_blue:before {
    content: "\f1a4";
}
.bu-icon-timeline_alert_dot_red:before {
    content: "\f1a5";
}
.bu-icon-timeline_buying_team_email:before {
    content: "\f1a6";
}
.bu-icon-timeline_crm_activity:before {
    content: "\f1a7";
}
.bu-icon-timeline_meeting:before {
    content: "\f1a8";
}
.bu-icon-timeline_next_steps:before {
    content: "\f1a9";
}
.bu-icon-timeline_selling_team_email:before {
    content: "\f1aa";
}
.bu-icon-to_do:before {
    content: "\f1ab";
}
.bu-icon-trash:before {
    content: "\f1ac";
}
.bu-icon-triangleDown:before {
    content: "\f1ad";
}
.bu-icon-triangleLeft:before {
    content: "\f1ae";
}
.bu-icon-triangleRight:before {
    content: "\f1af";
}
.bu-icon-triangleUp:before {
    content: "\f1b0";
}
.bu-icon-unlock:before {
    content: "\f1b1";
}
.bu-icon-upload:before {
    content: "\f1b2";
}
.bu-icon-user_group:before {
    content: "\f1b3";
}
.bu-icon-user-pivot-round:before {
    content: "\f1b4";
}
.bu-icon-user-pivot:before {
    content: "\f1b5";
}
.bu-icon-user:before {
    content: "\f1b6";
}
.bu-icon-vertical_resize:before {
    content: "\f1b7";
}
.bu-icon-workload-pivot-round:before {
    content: "\f1b8";
}
.bu-icon-workload-pivot:before {
    content: "\f1b9";
}
